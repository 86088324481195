import * as React from "react"
import { graphql } from 'gatsby'
import Helmet from "react-helmet"

// markup
const AboutPage = ({data}) => {
    let page = data.wpPublicpage.publicaboutpage;


    return (
        <>
            <Helmet htmlAttributes={{
                lang: 'en',
            }}>
                <title>About Careway | Careway</title>
                <meta name="description" content="Careway is an ACC funded programme that provides injured New Zealanders with better and faster access to great healthcare services. You will receive the care you need, when you need it, at no cost to yourself." />
                
            </Helmet>
            <div className="hero about-hero" style={{backgroundImage: "url('/img/Hero-Image-1-Runner-A.jpg')", backgroundPositionY:"45%"}}>
                <div className="hero-container">
                    <h1>{page.title}</h1>
                </div>
            </div>
            <div className="section highlight-section">
                <div className="section-inner">
                    <div className="highlight-section-blurb">
                        <p>{page.subtitle}</p>
                    </div>

                    {page.checkboxes.map(element => {
                        return (
                            <div className="highlight-section-point icon-blurb">
                                <div className="icon-blurb-content" >
                                    <p className="icon-blurb-header">{element.title}</p>
                                    <div dangerouslySetInnerHTML={{ __html: element.content }} />
                                </div>
                            </div>
                        )
                    })}


                </div>
            </div>
            <div className="section about-section">
                <div className="section-inner">
                    <div className="about-blurb">
                        <div className="about-blurb-inner">
                            <div className="about-blurb-stripe">
                                <div className="stripe"></div>
                            </div>
                            <div className="about-blurb-content">
                                <h2>{page.box1.title}</h2>
                                <div dangerouslySetInnerHTML={{__html:page.box1.content}} />
                            </div>
                            <div className="about-blurb-image">
                                <img src="/img/17079534642.png" alt="Who Can Access the Service"/>
                </div>
                            </div>
                        </div>
                        <div className="about-blurb about-blurb-secondary">
                            <div className="about-blurb-inner">
                                <div className="about-blurb-stripe">
                                    <div className="stripe"></div>
                                </div>
                                <div className="about-blurb-content">
                                    <h2>{page.box2.title}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: page.box2.content }} />
                                </div>
                                <div className="about-blurb-image">
                                    <img className="img-xl" src="/img/167112023.png" alt="What Services Can I Access"/>
                                </div>
                                </div>
                            </div>
                            <div className="about-blurb about-blurb-tertiary">
                                <div className="about-blurb-inner">
                                    <div className="about-blurb-stripe">
                                        <div className="stripe"></div>
                                    </div>
                                    <div className="about-blurb-content">
                                        <h2>{page.box3.title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: page.box3.content }} />
                                    </div>
                                    <div className="about-blurb-image">
                                        <img className="img-sml" src="/img/561816208.png" alt="What Can I Expect" />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section about-pathway-section">
                            <div className="section-inner">
                                <div className="about-pathway">
                                    <h2>{page.infographic.title}</h2>
                                    <p>{page.infographic.subtitle}</p>
                                    <img src={page.infographic.infographicImage.sourceUrl} alt={page.infographic.infographicImage.altText} />
                                </div>
                            </div>
                        </div>
           
        </>
    )
}

export const query = graphql`
    query AboutPageQuery {
    wpPublicpage(id: {eq: "cG9zdDoxNjEw"}) {
        publicaboutpage {
        title
        subtitle
        checkboxes {
            title
            content
        }
        box1 {
            title
            content
        }
        box2 {
            title
            content
        }
        box3 {
            title
            content
        }
        infographic {
            title
            subtitle
            infographicImage {
            altText
            sourceUrl
            }
        }
        }
    }
    }
`;

export default AboutPage
